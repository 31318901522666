// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
//Icons
$bootstrap-icons-font-src: url("/public/fonts/vendor/bootstrap-icons/bootstrap-icons.woff2") format("woff2"), url("/public/fonts/vendor/bootstrap-icons/bootstrap-icons.woff") format("woff");
@import '~bootstrap-icons/font/bootstrap-icons';

// Select2 theme
@import '~select2';
$s2bs5-border-color: $border-color;
@import '~select2-bootstrap-5-theme';

@import '~jstree/dist/themes/default-dark/style.css';

@import '~daterangepicker';
@import '~datatables.net-bs5';
@import '~datatables.net-buttons-bs5';

.row > .form-floating > label {
    margin-left:0.75em;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

.filter-group {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
}

.modal.modal-jstree {
    max-height: 80vh;
}

.modal.modal-jstree .jstree {
    max-height: 50vh;
    overflow-y: auto;
}

.delete-jstree-btn {
    position: absolute;
    top: calc(50% - 20px);
    right: 55px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.overlay {
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1100;
    left: 0px;
    opacity: 0.5;
    filter: alpha(opacity=50);
 }

#zx-report-body {
    background-color: white;
    width: 300px;
    height: 90%;
}

#spinner {
    margin-top: 15% !important;
}

.custom_btn:hover {
    color: white;
}
/* 11111 */

/*  custom tabs */
/* Style the tab */
.custom_tab {
  /*float: left;*/
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  /*width: 30%;*/
  height: auto;
}

/* Style the buttons that are used to open the tab content */
.custom_tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 12px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.custom_tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.custom_tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  /*float: left;*/
  padding: 0px 12px;
  border: 1px solid #ccc;
  /*width: 70%;*/
  border-left: none;
  height: 100%;
}

.simple_table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}
.simple_table tr {
    border: 1px solid black;
}
.simple_table th {
    border: 1px solid black;
}
.simple_table td {
    border: 1px solid black;
}
/* ------------ */
